<template>
  <div>
    <div class="z-10 flex justify-end sm:justify-between gap-4 items-center mb-2 p-1 md:px-0 relative">
      <div class="w-full sm:w-auto">
        <label for="search" class="sr-only">Quick search</label>
        <div class="relative flex items-center">
          <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon class="-ml-1 mr-2 h-5 w-5 text-gray-500" aria-hidden="true" />
          </div>
          <input
            id="search"
            ref="searchField"
            v-model="search"
            type="text"
            name="search"
            placeholder="Search"
            class="shadow-sm focus:ring-highlight-500 focus:border-highlight-500 block w-full pl-8 pr-12 sm:text-sm border-gray-300 rounded-md"
            @keydown.enter="unFocusField()"
            @input="searchHandler()" />
          <div class="hidden md:block absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
            <kbd class="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400"> {{ actionKey }}K </kbd>
          </div>
        </div>
      </div>
      <div class="flex space-x-2 sm:mt-0 self-end">
        <Popover as="div" class="relative inline-block text-left">
          <div>
            <PopoverButton class="btn-sm btn-white flex">
              <FilterIcon class="md:-ml-1 md:mr-2 mx-0 h-6 w-6 sm:h-5 sm:w-5 text-gray-500" aria-hidden="true" />
              <span class="hidden md:block">
                Filter <span v-if="filterAmount" class="absolute -top-2 -right-2 flex items-center justify-center
                   h-5 w-5 rounded-full bg-white border border-gray-700 text-xs">
                  {{filterAmount}}
                </span>
              </span>
            </PopoverButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95">
            <PopoverPanel class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
              <div class="bg-gray-100 flex justify-between items-center p-1.5 rounded-t-md">
                <PopoverButton :as="button" class="btn-xxs btn-white" @click="clearFilters">Clear</PopoverButton>
                <span class="block text-sm text-gray-700 font-medium">Filters</span>
                <PopoverButton :as="button" class="btn-xxs btn-highlight">Save</PopoverButton>
              </div>
              <div>
                <div class="block w-full text-left text-sm">
                  <div class="relative flex items-start text-sm self-center">
                    <label for="showall" class="w-full flex font-medium text-gray-700 px-3 py-2">
                      <input
                        id="showall"
                        v-model="showall"
                        aria-describedby="showall"
                        name="showall"
                        :checked="open"
                        type="checkbox"
                        class="flex mr-2 focus:ring-highlight-500 h-4 w-4 text-highlight-600 border-gray-300 rounded" />
                      Previous bookings
                    </label>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchIcon } from "@heroicons/vue/solid";
// import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import debounce from "@/helpers/debounce";

export default {
  name: "BookingSearchAndFiltering",
  components: {
    SearchIcon
    // FilterIcon,
    // Popover,
    // PopoverPanel,
    // PopoverButton
  },
  // props: {
  //   filter: {
  //     type: Object,
  //     required: true
  //   }
  // },
  data() {
    return {
      search: "",
      showall: this.$route.query.showall === 'true' || undefined,
      filterQuery: {
        showall: false,
        status: null
      }
    }
  },
  computed: {
    filterAmount() {
      return this.showall === 'true' ? 1 : 0;
    },
    actionKey() {
      if (typeof navigator !== 'undefined') {
        if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
          return '⌘'
        }
        return 'Ctrl + '
      }
      return 'Ctrl + '
    }
  },
  watch: {
    showall(newVal) {
      this.$router.push({ query: { ...this.$route.query, showall: newVal } });
    },
    '$route.query.showall': function(val) {
      this.showall = val;
    }
  },
  mounted() {
    window.addEventListener("keydown", this.focusField);
    this.search = this.$route.query.search;
  },
  beforeUnmount() {
    window.addEventListener("keydown", this.focusField);
  },
  methods: {
    saveFilters() {
      this.$router.push({
        ...this.$route.query,
        query: this.filterQuery
      })
    },
    resetQuery() {
      this.filterQuery = {
        showall: undefined,
        status: undefined
      };
    },
    clearFilters() {
      this.resetQuery()

      this.$router.push({
        ...this.$route.query,
        query: {
          showall: undefined,
          status: undefined
        }
      })
    },
    focusField(e) {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        this.$refs.searchField.focus();
      }
    },
    unFocusField() {
      this.$refs.searchField.blur();
    },
    searchHandler() {
      debounce(() => {
        this.$router.push({ query: {
            ...this.$route.query,
            page: undefined,
            search: this.search || undefined
        } })
      }, 600)
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-place {
  --ms-font-size: 0.8rem;
  --ms-line-height: 1.375;
  --ms-bg: #ffffff;
  --ms-bg-disabled: #f3f4f6;
  --ms-border-color: #d1d5db;
  --ms-border-width: 1px;
  --ms-radius: 4px;
  --ms-py: 0.5rem;
  --ms-px: 0.875rem;
  --ms-ring-width: 3px;
  --ms-ring-color: #10b98130;
  --ms-placeholder-color: #9ca3af;
  --ms-max-height: 10rem;

  --ms-spinner-color: #10b981;
  --ms-caret-color: #999999;
  --ms-clear-color: #999999;
  --ms-clear-color-hover: #000000;

  --ms-tag-font-size: 0.875rem;
  --ms-tag-line-height: 1.25rem;
  --ms-tag-font-weight: 600;
  --ms-tag-bg: #10b981;
  --ms-tag-bg-disabled: #9ca3af;
  --ms-tag-color: #ffffff;
  --ms-tag-color-disabled: #ffffff;
  --ms-tag-radius: 4px;
  --ms-tag-py: 0.125rem;
  --ms-tag-px: 0.5rem;
  --ms-tag-my: 0.25rem;
  --ms-tag-mx: 0.25rem;

  --ms-tag-remove-radius: 4px;
  --ms-tag-remove-py: 0.25rem;
  --ms-tag-remove-px: 0.25rem;
  --ms-tag-remove-my: 0rem;
  --ms-tag-remove-mx: 0.125rem;

  --ms-dropdown-bg: #ffffff;
  --ms-dropdown-border-color: #d1d5db;
  --ms-dropdown-border-width: 1px;
  --ms-dropdown-radius: 4px;

  --ms-group-label-py: 0.3rem;
  --ms-group-label-px: 0.75rem;
  --ms-group-label-line-height: 1.375;
  --ms-group-label-bg: #e5e7eb;
  --ms-group-label-color: #374151;
  --ms-group-label-bg-pointed: #d1d5db;
  --ms-group-label-color-pointed: #374151;
  --ms-group-label-bg-disabled: #f3f4f6;
  --ms-group-label-color-disabled: #d1d5db;
  --ms-group-label-bg-selected: #059669;
  --ms-group-label-color-selected: #ffffff;
  --ms-group-label-bg-selected-pointed: #0c9e70;
  --ms-group-label-color-selected-pointed: #ffffff;
  --ms-group-label-bg-selected-disabled: #75cfb1;
  --ms-group-label-color-selected-disabled: #d1fae5;

  --ms-option-font-size: 0.8rem;
  --ms-option-line-height: 1.375;
  --ms-option-bg-pointed: #ffffff;
  --ms-option-color-pointed: #1f2937;
  --ms-option-bg-selected: #10b981;
  --ms-option-color-selected: #ffffff;
  --ms-option-bg-disabled: #ffffff;
  --ms-option-color-disabled: #d1d5db;
  --ms-option-bg-selected-pointed: #26c08e;
  --ms-option-color-selected-pointed: #ffffff;
  --ms-option-bg-selected-disabled: #ffffff;
  --ms-option-color-selected-disabled: #d1fae5;
  --ms-option-py: 0.5rem;
  --ms-option-px: 0.75rem;

  --ms-empty-color: #4b5563;
}
</style>
