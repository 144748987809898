<template>
  <div>
    <div
      class="px-4 py-3 flex items-center justify-between sm:px-6">
      <div class="flex-1 text-center sm:text-left sm:flex sm:flex-row-reverse sm:items-center sm:justify-between">
        <div>
          <nav
            v-if="totalResults > perPage"
            class="relative z-0 inline-flex rounded-md -space-x-px"
            aria-label="Pagination">
            <button
              :disabled="page < 2 || page === undefined"
              class="relative inline-flex items-center px-2 py-1 sm:px-3 sm:py-2 border border-gray-300 text-sm
              font-medium rounded-l-md text-gray-700 bg-white hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50"
              @click="prevPage">
              <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
            </button>
            <router-link
              v-for="(page, index) in numOfPages"
              :key="page.page + index"
              :to="page.link"
              aria-current="page"
              class="relative inline-flex items-center px-3 py-2 sm:px-4 sm:py-2 border text-sm font-medium"
              :class="page.page === +$route.query.page || (isNaN(+$route.query.page) && page.page === 1)
                ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 '">
              {{ page.page }}
            </router-link>
            <button
              :disabled="page < 0 || showingTo === totalResults"
              class="ml-3 relative inline-flex items-center px-2 py-1 sm:px-3 sm:py-2 border border-gray-300 text-sm
              font-medium rounded-r-md text-gray-700 bg-white hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50"
              @click="nextPage">
              <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
        <div>
          <p class="text-sm text-gray-700 mt-4 md:mt-0">
            Showing
            <span class="font-medium">{{ showingFrom }}</span>
            to
            <span class="font-medium">{{ showingTo }}</span>
            of
            <span class="font-medium">{{ totalResults }}</span>
            {{ label }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    page: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    totalResults: {
      type: Number,
      required: true
    },
    label: {
      type: String,
      default: 'results'
    }
  },
  methods: {
    nextPage() {
      this.$router.push({ query: { ...this.$route.query, page: Number(this.page) + 1 || 2 } })
    },
    prevPage() {
      if(Number(this.page) === 2) {
        this.$router.push({ query: { ...this.$route.query, page: undefined } })
      } else {
        this.$router.push({ query: { ...this.$route.query, page: Number(this.page) - 1 } })
      }
    }
  },
  computed: {
    showingFrom() {
      return (Number(this.page) - 1) * this.perPage + 1 || 1;
    },
    showingTo() {
      const showTo = Number(this.page) * this.perPage;
      return showTo > this.totalResults ? this.totalResults : showTo
    },
    numOfPages() {
      const totalPages = Math.ceil(this.totalResults / this.perPage);
      if (this.page < 5 && totalPages <= 5) {
        return [...new Array(totalPages)].map((item, index) => ({
          page: index + 1,
          isDots: false,
          link: {
            query: {
              ...this.$route.query,
              page: index + 1
            }
          }
        }));
      }
      if (this.page < 5 && totalPages > 5) {
        return [...new Array(5)].map((item, index) => ({
          page: index + 1,
          isDots: false,
          link: {
            query: {
              ...this.$route.query,
              page: index + 1
            }
          }
        }));
      }
      if (this.page > totalPages - 4) {
        return [...new Array(5)].map((item, index) => ({
          page: totalPages - 5 + index + 1,
          isDots: false,
          link: {
            query: {
              ...this.$route.query,
              page: totalPages - 5 + index + 1
            }
          }
        }));
      }

      return [
        {
          page: 1,
          isDots: false,
          link: {
            query: {
              ...this.$route.query,
              page: 1
            }
          }
        },
        {
          page: '...',
          isDots: true,
          link: '#'
        },
        {
          page: +this.page - 1,
          isDots: false,
          link: {
            query: {
              page: this.page - 1,
              ...this.$route.query
            }
          }
        },
        {
          page: +this.page,
          isDots: false,
          link: ({
            query: {
              ...this.$route.query,
              page: this.page
            }
          })
        },
        {
          page: +this.page + 1,
          isDots: false,
          link: {
            query: {
              ...this.$route.query,
              page: +this.page + 1
            }
          }
        },
        {
          page: '...',
          isDots: true,
          link: '#'
        },
        {
          page: totalPages,
          isDots: false,
          link: {
            query: {
              ...this.$route.query,
              page: totalPages
            }
          }
        }
      ];
    }
  }
};
</script>
