<template>
  <div>
    <TransitionRoot as="template" :show="isOpen">
      <Dialog :open="isOpen" as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="$emit('toggleModal')">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden
            shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-md sm:w-full">
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12
                  rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                      <slot name="title"></slot>
                    </DialogTitle>
                    <div class="mt-2">
                      <slot name="content"></slot>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="w-full inline-flex justify-center rounded-md border
                border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white
                hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                sm:ml-3 sm:w-auto sm:text-sm"
                        @click="$emit('continue')">
                  <slot name="buttonName"></slot>
                </button>
                <button ref="cancelButtonRef" type="button" class="mt-3 w-full inline-flex justify-center
                rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base
                font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2
                focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        @click="$emit('toggleModal')">
                  Go back
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";
import { ExclamationIcon } from '@heroicons/vue/outline'

export default {
  name: "AppModal",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    ExclamationIcon
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggleModal', 'continue']
};
</script>
