<template>
  <div class="text-center">
    <h3 class="text-xl font-medium text-gray-900">No results</h3>
    <p class="mt-1 text-sm text-gray-500">
      Please adjust your search or reset filters
    </p>
    <div class="mt-6">
      <button class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click="this.$emit('resetFilters')">
        Reset filters
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyTable"
};
</script>

<style scoped>

</style>
