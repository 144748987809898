import gql from "graphql-tag";

const SEND_WELCOME_EMAIL = gql`
  mutation sendWelcomeEmail($id: Int!) {
    sendWelcomeEmail(id: $id)
  }
`;

const SEND_CONFIRMATION_EMAIL = gql`
  mutation sendConfirmationEmail($id: Int!) {
    sendConfirmEmail(id: $id)
  }
`;

const UPDATE_CUSTOMER_INFO = gql`
  mutation updateCustomerEmail($id: Int!, $email: String, $tel: String) {
    updateCustomerEmail(id: $id, email: $email, tel: $tel) {
      id
    }
  }
`;

const ADD_COMMENT_TO_BOOKING = gql`
  mutation addCommentToBooking($bookingId: Int!, $comment: String!) {
    addCommentToBooking(bookingId: $bookingId, comment: $comment) {
      booking
      content
      id
    }
  }`;

const CANCEL_PAYMENT = gql`
  mutation cancelPayment($data: CancelBookingInput!) {
    cancelPayment(data: $data) {
      id
    }
  }
`;

export {
  SEND_WELCOME_EMAIL,
  SEND_CONFIRMATION_EMAIL,
  UPDATE_CUSTOMER_INFO,
  ADD_COMMENT_TO_BOOKING,
  CANCEL_PAYMENT
}
