<template>
  <div class="bg-gray-100 relative min-h-screen">
    <div v-if="!$apollo.queries.booking.loading">
      <div class="bg-white border-b">
        <div class="max-w-4xl mx-auto flex relative">
          <router-link
            to="/bookings"
            class="flex py-3 px-3 items-center border-r text-sm text-gray-600 hover:bg-gray-50">
            <ChevronLeftIcon class="h-5 w-5 mr-1" /> Bookings
          </router-link>
          <div class="py-3 px-4">
            <div class="flex items-center gap-2">
              <h1 class="font-medium text-universeBlue text-base">
                Booking #{{ booking.id }}
              </h1>
              <div class="absolute md:relative top-2 right-2 md:top-0 md:right-0">
                <span
                  class="block md:mb-1 inline-flex items-center px-1.5 py-0.5 rounded-full text-xs
                  font-medium capitalize"
                  :class="booking.status === 'paid' ? 'bg-green-100 text-green-800' : booking.status == 'cancelled'
                    ? 'bg-red-100 text-red-500' : 'border bg-gray-100 text-gray-800'">
                  {{ booking.status }}
                </span>
              </div>
            </div>
            <div class="flex items-center gap-2">
              <p class="text-gray-600 text-xs">Placed {{ formatDate(booking.created_at, "D MMM YYYY • HH:mm") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="max-w-4xl mx-auto px-4 pb-10 py-6 lg:py-6 lg:px-8">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12 md:col-span-8">
            <div class="bg-white rounded-md border relative mb-6 overflow-hidden">
              <div class="flex items-center overflow-hidden">
                <div class="flex-grow hover:bg-gray-50 pt-2 pb-4 px-3 md:px-6 border-r">
                  <div
                    class="px-3 py-1 rounded-r-md -ml-3 md:-ml-6 inline-block mb-3"
                    :class="booking.status === 'cancelled' ? 'bg-gray-400 ' : 'bg-water' ">
                    <span class="block uppercase text-xs text-white font-medium">Check-in</span>
                  </div>
                  <span
                    class="block font-medium text-sm sm:text-lg mb-1"
                    :class="booking.status === 'cancelled' ? 'line-through text-gray-400' : 'text-gray-800'">
                    {{ formatDate(booking.bookings[0].from_date, "DD MMMM YYYY") }}
                  </span>
                  <span
                    class="block text-xs sm:text-sm"
                    :class="booking.status === 'cancelled' ? 'line-through text-gray-400' : 'text-gray-600'">
                    From {{ booking.bookings[0].product.parent.check_in }}
                  </span>
                </div>
                <div class="w-10 md:w-16 h-full py-4 text-center">
                  <span class="h-full"><ArrowSmRightIcon class="h-6 w-6 md:h-8 md:w-8 mx-auto text-gray-400" /></span>
                </div>
                <div class="flex-grow hover:bg-gray-50 pt-2 pb-4 px-3 md:px-6 border-l">
                  <div
                    class="px-3 py-1 rounded-r-md -ml-3 md:-ml-6 inline-block mb-3"
                    :class="booking.status === 'cancelled' ? 'bg-gray-400 ' : 'bg-water' ">
                    <span class="block uppercase text-xs text-white font-medium">Check-out</span>
                  </div>
                  <span
                    class="block font-medium text-sm sm:text-lg mb-1"
                    :class="booking.status === 'cancelled' ? 'line-through text-gray-400' : 'text-gray-800'">
                    {{ formatDate(booking.bookings[0].to_date, "DD MMMM YYYY") }}
                  </span>
                  <span
                    class="block text-xs sm:text-sm"
                    :class="booking.status === 'cancelled' ? 'line-through text-gray-400' : 'text-gray-600'">
                    {{ booking.bookings[0].product.parent.check_out }}
                  </span>
                </div>
              </div>
              <div class="p-4 md:p-6 border-t flex items-center gap-4">
                <div>
                  <p class="text-gray-800 font-medium text-lg">{{ booking.bookings[0].product.parent.translation.title }} </p>
                </div>
              </div>

              <div class="border-t flex flex-col md:flex-row gap-4 p-4 md:p-6">
                <div class="w-full md:w-1/2">
                  <p class="font-medium text-sm text-gray-500 mb-1">Addons</p>
                  <div v-if="booking.customer_addons.length > 0" class="space-y-1">
                    <p v-for="(add, index) in booking.customer_addons" :key="index" class="text-gray-900 text-sm">{{ add.qty }}x {{ add.addon.translation.title }}</p>
                  </div>
                  <p v-else class="text-gray-900 text-sm">No addons</p>
                </div>
                <div class="w-full md:w-1/2">
                  <p class="font-medium text-sm text-gray-500 mb-1">Comment from guest</p>
                  <p v-if="booking.customer_info?.additional_info" class="text-sm text-gray-900 prose-sm">
                    {{ booking.customer_info?.additional_info }}
                  </p>
                  <p v-else class="text-sm text-gray-900 prose-sm">No comment</p>
                </div>
              </div>
            </div>
            <div class="bg-white rounded-md border overflow-hidden mb-4">
              <div class="p-4">
                <div class="mb-4">
                  <p class="font-bold text-lg text-gray-800">Booking info</p>
                </div>
                <div>
                  <div class="flex items-top gap-4 py-3">
                    <div class="w-24 flex-shrink-0">
                      <div class="aspect-w-16 aspect-h-12">
                        <img v-if="booking.bookings[0].product.parent.media" class="object-cover rounded-lg" :src="`https://owayy.imgix.net/${booking.bookings[0].product.parent.media.media.path}?fm=jp2&w=768&h=768&fit=max&auto=format,compress`" />
                      </div>
                    </div>
                    <div class="w-full">
                      <p class="text-gray-800 font-medium text-lg">{{ booking.bookings[0].product.parent.translation.title }} </p>
                      <p class="text-gray-600 text-sm">
                        {{ formatDate(booking.bookings[0].from_date, "DD MMMM YYYY") }}
                        -
                        {{ formatDate(booking.bookings[0].to_date, "DD MMMM YYYY") }}
                        <br />
                        <span class="flex items-center">{{ difference(booking.bookings[0].from_date, booking.bookings[0].to_date) }}</span>
                        <span class="flex items-center"> {{ booking.customer_info.adults }} adults </span>
                        <span v-if="booking.customer_info.children > 0" class="flex items-center mr-2"> {{ booking.customer_info.children }} children </span>
                        <span v-if="booking.customer_info.infants > 0" class="flex items-center mr-2"> {{ booking.customer_info.infants }} infants </span>
                      </p>
                      <div class="flex justify-end">
                        <span class="text-gray-700 font-medium text-sm">{{ booking.bookings[0].price.toFixed(0) }} {{ currencyCode }}</span>
                      </div>
                      <div v-if="booking.customer_addons.length > 0" class="mt-4">
                        <p class="font-medium text-gray-800 mb-1">Addons</p>
                        <div v-if="booking.customer_addons.length > 0" class="space-y-1">
                          <p v-for="(add, index) in booking.customer_addons" :key="index" class="text-gray-900 text-sm">
                            {{ add.qty }}x {{ add.addon.translation.title }} <br />
                            <span class="text-xs text-gray-600"> ({{ add.price }} {{ currencyCode }}/each) </span>
                          </p>
                        </div>
                        <p v-else class="text-gray-900 text-sm">No addons</p>
                        <div v-if="booking.customer_addons.length > 0" class="flex justify-end">
                          <span class="text-gray-700 font-medium text-sm">{{ sumOfNumbers(booking.customer_addons.map((a) => a.price * a.qty)).toFixed(0) }} {{ currencyCode }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="booking.gift_card" class="border-t p-4 flex items-center">
                <Popper v-role="['admin']" :hover="true" arrow placement="top" :content="`Giftcard (${booking.gift_card.code})`">
                  <div class="flex items-center">
                    <InformationCircleIcon class="h-4 w-4 mr-2 text-water" />
                    <span class="text-xs text-gray-700">The guest used a giftcard/promotion code for this booking</span>
                  </div>
                </Popper>
                <span v-role="['host']" class="text-xs text-gray-700">The guest used a giftcard/promotion code for this booking</span>
              </div>

              <div class="border-t bg-gray-50">
                <div class="px-4 pt-2">
                  <div class="flex justify-between items-center">
                    <span class="text-gray-600 text-sm">Total (paid)</span>
                    <span class="font-medium text-gray-600 text-sm">{{ totalWithoutFee.toFixed(0) }} {{ currencyCode }}</span>
                  </div>
                </div>
                <div class="px-4">
                  <div class="flex justify-between items-center">
                    <span class="text-gray-600 text-sm">Owayy fee (5% +VAT)</span>
                    <span class="font-medium text-gray-600 text-sm">{{ (totalWithoutFee*0.05).toFixed(0) }} {{ currencyCode }}</span>
                  </div>
                </div>
                <div class="px-4 pb-2">
                  <div class="flex justify-between items-center">
                    <span class="text-gray-700 font-bold text-sm">Payout</span>
                    <span class="font-medium text-gray-700 font-bold text-sm">{{ (totalWithoutFee-(totalWithoutFee*0.05)).toFixed(0) }} {{ currencyCode }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="bg-white rounded-md border mt-5 overflow-hidden relative py-4">
              <div class="px-4 mb-4">
                <div class="mb-2 flex justify-between ">
                  <p class="font-bold text-lg">Host info</p>
                  <a :href="getListingsUrl" target="_blank" class="btn btn-xxs btn-white flex items-center">
                    <ExternalLinkIcon class="h-4 w-4 mr-1" />
                    Go to listing
                  </a>
                </div>
              </div>
              <div class="flex flex-col md:flex-row items-center gap-4 md:gap-6">
                <div class="hidden md:block sm:w-72">
                  <div class="aspect-h-6 aspect-w-10  md:aspect-h-12 md:aspect-w-10 overflow-hidden">
                    <img v-if="booking.bookings[0].product.parent.media" class="md:rounded-r-lg object-cover overflow-hidden" :src="`https://owayy.imgix.net/${booking.bookings[0].product.parent.media.media.path}?fm=jp2&w=768&h=768&fit=max&auto=format,compress`" />
                  </div>
                </div>
                <div class="w-full p-4 md:p-0 pr-5">
                  <p class="font-medium text-gray-800">{{ booking.bookings[0].product.parent.translation.title }}</p>
                  <p class="text-gray-600 text-sm">
                    {{ booking.bookings[0].product.parent.address.split(",")[0] }} <br />
                    {{ booking.bookings[0].product.parent.address.split(",")[1] }} <br />
                    {{ booking.bookings[0].product.parent.address.split(",")[2] }}
                  </p>
                  <div class="mt-3 border rounded-lg bg-white p-3 inline-block">
                    <div class="relative flex items-center space-x-3">
                      <div class="w-full flex-shrink-0 min-w-0 pr-4">
                        <span class="absolute inset-0" aria-hidden="true" />
                        <p class="font-medium text-gray-800">{{ booking.landlord.first_name }} {{ booking.landlord.last_name }}</p>
                        <p class="text-xs text-gray-600">
                          {{ booking.landlord.email }} <br />
                          {{ booking.bookings[0].product.parent.phone }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 md:col-span-4">
            <div class="bg-white rounded-md border divide-y mb-4">
              <div class="p-4">
                <div class="flex justify-between items-center">
                  <p class="font-bold text-lg">Internal note</p>
                  <button v-if="!addComment" class="text-sm text-blue-600" @click="toggleAddComment">Add</button>
                </div>
                <div class="text-sm text-gray-500 mt-4">
                  <div v-if="addComment" class="mb-3 border-b pb-3">
                    <label
                      for="comment"
                      class="text-sm font-medium text-gray-600">
                      Write a comment
                    </label>
                    <textarea
                      id="comment"
                      v-model="comment"
                      name="comment"
                      class="mt-1 block w-full py-2 px-3 sm:text-sm rounded-md text-gray"
                      :class="true ? 'border border-gray-300 shadow-sm focus:outline-none ' +
                        'focus:ring-highlight-500 focus:border-highlight-500 text-gray-600'
                        : 'bg-gray-100 pointer-events-none border-transparent'" />
                    <div class="flex justify-end mt-1">
                      <button
                        class="mt-2 btn btn-xxs flex justify-center btn-highlight disabled:bg-white disabled:cursor-not-allowed
                        disabled:text-gray-800 disabled:border disabled:border-gray-300 disabled:opacity-30"
                        :disabled="!comment"
                        @click="addCommentToBooking">
                        Add
                      </button>
                    </div>
                  </div>
                  <div v-if="comments.length > 0" class="max-h-80 overflow-y-scroll relative">
                    <ul class="relative">
                      <li
                        v-for="(comment, idx) in comments"
                        :key="comment.id"
                        class="flex items-top relative gap-2 pb-2">
                        <span v-if="idx !== comments.length - 1" class="absolute top-4 left-1 h-full w-0.5 bg-gray-200" aria-hidden="true" />
                        <div class="flex-shrink-0 mt-1.5 h-2.5 w-2.5 rounded-full bg-gray-400 border-2"></div>
                        <p class="text-gray-600" v-html="comment.content"></p>
                      </li>
                    </ul>
                  </div>
                  <p v-else class="text-sm text-gray-500 mt-4">
                    No internal note
                  </p>
                </div>
              </div>
            </div>
            <div class="bg-white rounded-md border divide-y mb-4">
              <div class="p-4">
                <p class="font-bold text-lg">Customer</p>
                <p class="text-sm mt-4 text-gray-900">
                  <span class="font-medium text-gray-500">Name</span>
                  <br />
                  {{ booking.customer_info.first_name }} {{ booking.customer_info.last_name }}
                </p>
              </div>
              <div class="p-4">
                <div class="flex justify-between items-center mb-4">
                  <p class="font-bold text-sm text-gray-800">Contact info</p>
                  <button v-if="!editContactInfo" class="text-sm text-blue-600" @click="toggleEditContactInfo">Edit</button>
                </div>
                <label for="email" class="text-sm font-medium text-gray-500">E-mail</label>
                <input
                  id="email"
                  v-model.trim="email"
                  type="email"
                  name="email"
                  autocomplete="email"
                  class="block w-full sm:text-sm rounded-md mb-4"
                  :class="editContactInfo ? 'border border-gray-300 shadow-sm focus:outline-none ' +
                    'focus:ring-highlight-500 focus:border-highlight-500 py-2 px-3 mt-1'
                    : 'p-0 pointer-events-none border-transparent'" />

                <label for="phone" class="text-sm font-medium text-gray-500">Phone</label>
                <input
                  id="phone"
                  v-model.trim="phone"
                  name="phone"
                  class="block w-full sm:text-sm rounded-md"
                  :class="editContactInfo ? 'border border-gray-300 shadow-sm focus:outline-none ' +
                    'focus:ring-highlight-500 focus:border-highlight-500 py-2 px-3 mt-1'
                    : 'p-0 pointer-events-none border-transparent'" />
                <div
                  v-if="editContactInfo"
                  class="flex justify-end items-center gap-2 mt-5 mb-3">
                  <button
                    class="btn btn-xxs btn-transparent"
                    @click="cancelContactInfo">
                    Cancel
                  </button>
                  <button
                    class="btn btn-xxs flex justify-center btn-highlight"
                    @click="editContactInfoHandler">
                    Save
                  </button>
                </div>
              </div>
              <div v-role="['admin']" class="p-4 relative">
                <div class="flex justify-between items-center">
                  <p class="font-bold text-gray-800 text-sm">Customer address</p>
                </div>
                <p class="text-sm text-gray-600 mt-4">
                  {{ booking.customer_info.street }} <br />
                  {{ booking.customer_info.postal_code }} {{ booking.customer_info.city }} <br />
                  {{ booking.customer_info.region }}
                </p>
              </div>
            </div>
            <div v-if="booking.status !== 'cancelled'" class="bg-white rounded-md border divide-y mb-4">
              <div class="p-4">
                <p class="font-bold text-lg">Actions</p>
                <div class="mt-4">
                  <button
                    class="my-3 py-2 btn btn-xs flex justify-center btn-white w-full disabled:opacity-60 disabled:cursor-not-allowed"
                    :disabled="hasConfirmationMailSend"
                    @click="sendConfirmationEmail">
                    <span v-if="!hasConfirmationMailSend && !isConfirmationMailSending">Resend confirmation email</span>
                    <span v-if="isConfirmationMailSending">Sending..</span>
                    <span v-if="hasConfirmationMailSend && !isConfirmationMailSending" class="flex items-center gap-2"><CheckIcon class="h-5 w-5 -ml-2 text-green-500" />Confirmation email has been send</span>
                  </button>
                  <button
                    class="my-3 py-2 btn btn-xs flex justify-center btn-white w-full disabled:opacity-60 disabled:cursor-not-allowed"
                    :disabled="hasWelcomeMailSend"
                    @click="sendWelcomeEmail">
                    <span v-if="!hasWelcomeMailSend && !isWelcomeMailSending">Resend welcome email</span>
                    <span v-if="isWelcomeMailSending">Sending..</span>
                    <span v-if="hasWelcomeMailSend && !isWelcomeMailSending" class="flex items-center gap-2"><CheckIcon class="h-5 w-5 -ml-2 text-green-500" />Welcome email has been send</span>
                  </button>
                </div>
              </div>
              <div v-role="['admin']" class="p-4 border-t bg-red-100 border-red-500">
                <button class="py-2 btn btn-xs flex justify-center text-red-500 btn-white w-full" @click="toggleCancelModal">Cancel order</button>
                <AppModal :isOpen="isCancelModalOpen" @toggleModal="toggleCancelModal" @continue="cancelPayment">
                  <template v-slot:title>
                    Cancel booking #{{ booking.id }}
                  </template>
                  <template v-slot:content>
                    <div class="mt-3 text-left">
                      <label for="phone" class="text-sm font-medium text-gray-600">Reason</label>
                      <input
                        id="reason"
                        v-model.trim="cancelReason"
                        name="phone"
                        class="block w-full sm:text-sm rounded-md mb-4"
                        :class="true ? 'border border-gray-300 shadow-sm focus:outline-none ' +
                          'focus:ring-highlight-500 focus:border-highlight-500 py-2 px-3 mt-1'
                          : 'p-0 pointer-events-none border-transparent'" />

                      <label for="word" class="text-sm font-medium text-gray-600">Type <span class="underline">DELETE</span> to cancel order</label>
                      <input
                        id="word"
                        v-model.trim="keyWord"
                        type="email"
                        name="email"
                        autocomplete="email"
                        class="block w-full sm:text-sm rounded-md mb-4 w-36"
                        :class="true ? 'border border-gray-300 shadow-sm focus:outline-none ' +
                          'focus:ring-highlight-500 focus:border-highlight-500 py-2 px-3 mt-1'
                          : 'p-0 pointer-events-none border-transparent'" />

                      <p class="input-error">{{ errorMessage }}</p>
                    </div>
                  </template>
                  <template v-slot:buttonName>
                    <p>Cancel order</p>
                  </template>
                </AppModal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="max-w-4xl mx-auto px-4 pb-10 lg:py-8 lg:px-6 pt-4">
        <div class="h-10 mb-2 w-full rounded-md bg-gray-200 animate-pulse"></div>
        <div class="hidden h-5 mb-4 w-24 rounded-md bg-gray-200 animate-pulse"></div>
        <div class="hidden h-10 mb-2 w-64 rounded-md bg-gray-200 animate-pulse"></div>
        <div class="hidden h-5 w-44 rounded-md bg-gray-200 animate-pulse"></div>
        <div class="grid md:grid-cols-12 gap-4 mt-6">
          <div class="col-span-8">
            <div class="h-80 mb-4 w-full rounded-md bg-gray-200 animate-pulse"></div>
            <div class="h-72 mb-4 w-full rounded-md bg-gray-200 animate-pulse"></div>
          </div>
          <div class="col-span-4">
            <div class="h-24 mb-4 w-full rounded-md bg-gray-200 animate-pulse"></div>
            <div class="h-72 mb-4 w-full rounded-md bg-gray-200 animate-pulse"></div>
            <div class="h-36 mb-4 w-full rounded-md bg-gray-200 animate-pulse"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ArrowSmRightIcon, InformationCircleIcon, ExternalLinkIcon, CheckIcon  } from "@heroicons/vue/solid";
import Popper from 'vue3-popper';
import dayjs from "dayjs";
import { ref } from "vue";
import { GET_ORDER } from "@/graphql/orders/queries";
import AppModal from "@/components/UI/Popups/Modal/AppModal";
import {
  ADD_COMMENT_TO_BOOKING, CANCEL_PAYMENT,
  SEND_CONFIRMATION_EMAIL,
  SEND_WELCOME_EMAIL,
  UPDATE_CUSTOMER_INFO
} from "@/graphql/orders/mutations";


export default {
  name: "Booking",
  components: {
    AppModal,
    ChevronLeftIcon,
    ArrowSmRightIcon,
    InformationCircleIcon,
    ExternalLinkIcon,
    CheckIcon,
    Popper
  },
  apollo: {
    booking: {
      query: GET_ORDER,
      update: data => data.getOrder,
      variables() {
        return {
          payment_id: this.payment_id
        }
      },
      result(data) {
        const { data: { getOrder: { bookings, customer_info: { email, tel } } } = {} } = data;
        this.email = email;
        this.phone = tel;
        this.comments = bookings[0].comments
      }
    }
  },
  data() {
    return {
      from: null,
      payment_id: this.$route.params.id,
      editContactInfo: false,
      addComment: false,
      hasConfirmationMailSend: false,
      isConfirmationMailSending: false,
      hasWelcomeMailSend: false,
      isWelcomeMailSending: false,
      comments: [],
      email: null,
      phone: null,
      comment: '',
      keyWord: '',
      cancelReason: '',
      errorMessage: ''
    }
  },
  setup() {
    const isCancelModalOpen = ref(false);
    const toggleCancelModal = () => {
      isCancelModalOpen.value = !isCancelModalOpen.value;
    };
    return {
      isCancelModalOpen,
      toggleCancelModal
    }
  },
  computed: {
    getListingsUrl() {
      return `${process.env.VUE_APP_CLIENT_URL}${this.booking.bookings[0].product.parent.urlm.url}`
    },
    currencyCode() {
      return this.$store.getters['product/getCurrencyCode'](this.booking.currency);
    }, 
    totalWithoutFee() {
      return this.booking.bookings[0].price + this.sumOfNumbers(this.booking.customer_addons.map((a) => a.price * a.qty));
    }
  },
  methods: {
    toggleAddComment() {
      this.addComment = !this.addComment;
    },
    toggleEditContactInfo() {
      this.editContactInfo = !this.editContactInfo;
    },
    cancelContactInfo() {
      this.editContactInfo = !this.editContactInfo;
      this.email = this.booking.customer_info.email;
      this.phone = this.booking.customer_info.tel;
    },
    formatDate(dateString, formatString) {
      const date = dayjs(dateString);
      // Then specify how you want your dates to be formatted
      return date.format(formatString);
    },
    difference(from, to) {
      const diff = dayjs(to).diff(from, 'days')
      return diff > 1 ? `${diff} nights` : `${diff} night`
    },
    sumOfNumbers(numOfArray) {
      let sum = 0;
      for (let i = 0; i < numOfArray.length; i += 1) {
        sum += numOfArray[i];
      }
      return sum
    },
    sendWelcomeEmail() {
      this.isWelcomeMailSending = true;
      this.$apollo.mutate({
        mutation: SEND_WELCOME_EMAIL,
        variables: {
          id: this.booking.id
        }
      })
      setTimeout(() => {
        this.isWelcomeMailSending = false;
        this.hasWelcomeMailSend = true;
      }, 1000)
    },
    sendConfirmationEmail() {
      this.isConfirmationMailSending = true;
      this.$apollo.mutate({
        mutation: SEND_CONFIRMATION_EMAIL,
        variables: {
          id: this.booking.id
        }
      })
      setTimeout(() => {
        this.isConfirmationMailSending = false;
        this.hasConfirmationMailSend = true;
      }, 1000)
    },
    editContactInfoHandler() {
      this.$apollo.mutate({
        mutation: UPDATE_CUSTOMER_INFO,
        variables: {
          id: this.booking.id,
          email: this.email,
          tel: this.phone
        }
      }).then(() => {
        this.$apollo.queries.booking.refetch({
          payment_id: this.payment_id
        })
      }).finally(() => {
        this.toggleEditContactInfo();
      })
    },
    addCommentToBooking() {
      if (this.comment === '') return;
      this.$apollo.mutate({
        mutation: ADD_COMMENT_TO_BOOKING,
        variables: {
          bookingId: this.booking.bookings[0].id,
          comment: this.comment
        }
      }).then(() => {
        this.$apollo.queries.booking.refetch({
          payment_id: this.payment_id
        })
      }).finally(() => {
        this.comment = '';
        this.addComment = false;
      })
    },
    cancelPayment() {
      if (this.keyWord !== "DELETE") {
        this.errorMessage = 'type DELETE to cancel the order';
        return;
      }
      this.$apollo.mutate({
        mutation: CANCEL_PAYMENT,
        variables: {
          data: {
            payment_id: this.booking.id,
            reason: this.cancelReason
          }
        }
      }).then(() => {
        this.$apollo.queries.booking.refetch({
          payment_id: this.payment_id
        });
        this.isCancelModalOpen =  false;
      }).finally(() => {
        this.keyWord = '';
        this.cancelReason = '';
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from;
    });
  }
};
</script>

<style scoped>
:deep(.popper) {
  @apply bg-universeBlue rounded-md px-2 py-2 text-white;
}

:deep(.popper #arrow::before) {
  @apply bg-universeBlue;
}
</style>
