<template>
  <div class="col-span-12">
    <div class="flex flex-col">
      <div class="-my-2 sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="sm:border overflow-hidden sm:border-b border-gray-200 sm:rounded-lg">
            <div class="w-full table-auto sm:divide-y divide-gray-200 bookings-table">
              <div class="sm:bg-gray-50">
                <div class="flex justify-end items-center divide-x">
                  <div class="hidden sm:block flex items-center gap-3">
                    <p class="sm:hidden font-medium text-xs text-gray-700">Sort</p>
                    <div
                      class="sm:w-36 cursor-pointer px-2 py-2 sm:px-3 sm:py-3 text-left text-xs border sm:border-none rounded-md sm:rounded-none
                    font-medium uppercase tracking-wider hover:bg-gray-100 active:bg-gray-200 bg-gray-50"
                      :class="$route.query.field ===  'CHECKIN' ?
                        'text-gray-700 bg-gray-100' : 'text-gray-500'"
                      @click="sort('CHECKIN', 'ASC')">
                      <div
                        class="flex items-center justify-between gap-2">
                        <span>Check in</span>
                        <span>
                          <ChevronUpIcon
                            class="h-4 w-4 "
                            :class="$route.query.field ===  'CHECKIN' && $route.query.type === 'DESC'  ? '' : 'rotate-180'" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="flex-auto hidden sm:block px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </div>
                  <div
                    class="sm:w-44 md:w-52 lg:w-60 hidden sm:block px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Place
                  </div>
                  <div
                    class="sm:w-28 hidden md:block cursor-pointer px-3 py-3 text-left text-xs font-medium
                   uppercase tracking-wider hover:bg-gray-100"
                    :class="$route.query.field ===  'CREATED_AT' ?
                      'text-gray-700 bg-gray-100' : 'text-gray-500'"
                    @click="sort('CREATED_AT', 'ASC')">
                    <div
                      class="flex justify-between items-center">
                      <span>Placed</span>
                      <span>
                        <ChevronUpIcon
                          class="h-4 w-4"
                          :class="$route.query.field ===  'CREATED_AT' && $route.query.type === 'DESC'  ? 'rotate-180' : ''" />
                      </span>
                    </div>
                  </div>
                  <div
                    class="sm:w-28 hidden sm:block px-3 py-3 text-center text-xs font-medium
                    text-gray-500 uppercase tracking-wider">
                    <span>Booking ID</span>
                  </div>
                </div>
              </div>
              <div v-if="bookings.items.length > 0" class="sm:bg-white sm:divide-y sm:divide-gray-200">
                <router-link
                  v-for="booking in bookings.items"
                  :key="booking.id"
                  :to="{ name: 'Booking', params: { id: booking.uuid }}"
                  custom
                  v-slot="{ navigate }">
                  <div class="hidden sm:flex flex-wrap sm:flex-nowrap sm:flex-row items-center cursor-pointer py-2 hover:bg-gray-50" @click="navigate">
                    <div class="sm:w-36 px-3 py-2 border-r border-gray-100 whitespace-nowrap text-sm text-gray-500">
                      <p
                        class="text-gray-800 flex items-center"
                        :class="booking.status === 'paid' ? '' : 'line-through'">
                        {{formatDate(booking.bookings[0].from_date, 'D MMM')}}> <ArrowSmRightIcon class="mx-1 h-3.5 w-3.5" /> {{formatDate(booking.bookings[0].to_date, 'D MMM')}}
                      </p>
                      <p class="text-xs">{{difference(booking.bookings[0].from_date, booking.bookings[0].to_date)}}</p>
                    </div>
                    <div class="sm:flex-auto px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                      <Popper :hover="true" arrow placement="top">
                        <div class="w-36 truncate">
                          <p class="truncate text-gray-700 font-medium">{{ `${booking.customer_info.first_name} ${booking.customer_info.last_name}` }}</p>
                          <p class="text-xs">{{booking.customer_info.number_of_people}} guests • {{booking.customer_addons.length}} addons</p>
                        </div>
                        <template #content>
                          <div>
                            <p class="font-bold">{{ `${booking.customer_info.first_name} ${booking.customer_info.last_name}` }}</p>
                            <p>
                              {{booking.customer_info.number_of_people}} guests • {{booking.customer_addons.length}} addons
                            </p>
                            <p>{{booking.customer_info.email}}</p>
                            <p>{{booking.customer_info.tel}}</p>
                            <div class="border-t border-gray-200 pt-2 mt-2">
                              <p>{{booking.bookings[0].product.translation.title}}</p>
                              <p>{{difference(booking.bookings[0].from_date, booking.bookings[0].to_date)}}</p>
                            </div>
                          </div>
                        </template>
                      </Popper>
                    </div>
                    <div class="sm:w-44 md:w-52 lg:w-60 px-3 py-2 text-sm text-gray-500">
                      <div>
                        <Popper :hover="true" arrow placement="top" :content="booking.bookings[0].product.translation.title">
                          <div class="line-clamp-1">
                            {{ booking.bookings[0].product.parent.translation.title }}
                          </div>
                        </Popper>
                      </div>
                    </div>
                    <div class="sm:w-28 sm:hidden md:block px-3 py-2 whitespace-nowrap text-center text-xs text-gray-500">
                      <Popper :hover="true" arrow placement="top" :content="formatDate(booking.created_at, 'D MMM YYYY • HH:mm')">
                        <p>{{ relativeFormatDate(booking.created_at) }}</p>
                      </Popper>
                    </div>
                    <div class="sm:w-28 px-3 py-2 whitespace-nowrap text-center text-sm">
                      <div class="justify-center flex items-center gap-2">
                        <p class="font-medium text-gray-800">#{{ booking.id }}</p>
                        <Popper :hover="true" arrow placement="top" :content="booking.status">
                          <div
                            class="h-2 w-2 rounded-full"
                            :class="booking.status === 'paid' ? 'bg-green-400' : 'bg-red-400'">
                          </div>
                        </Popper>
                      </div>
                    </div>
                  </div>
                  <!-- mobile-card -->
                  <div class="sm:hidden bg-white rounded-lg border my-2 p-4 relative" @click="navigate">
                    <div class="divide-y">
                      <div class="pb-3 flex flex-col items-center justify-between">
                        <div class="flex items-center gap-2">
                          <Popper :hover="true" arrow placement="top" :content="booking.status">
                            <div
                              class="h-2 w-2 rounded-full"
                              :class="booking.status === 'paid' ? 'bg-green-400' : 'bg-red-400'">
                            </div>
                          </Popper>
                          <p class="font-medium text-gray-800">#{{ booking.id }}</p>
                        </div>
                        <p class="text-xs text-gray-500">{{booking.customer_info.number_of_people}} guests • {{booking.customer_addons.length}} addons • {{difference(booking.bookings[0].from_date, booking.bookings[0].to_date)}}</p>
                      </div>
                      <div class="flex items-center justify-between py-2">
                        <p class="text-sm font-medium text-gray-700">
                          Check-in/out
                        </p>
                        <div>
                          <p class="text-gray-700 text-sm flex items-center">{{formatDate(booking.bookings[0].from_date, 'D MMM')}} <ArrowSmRightIcon class="mx-1 h-3.5 w-3.5" /> {{formatDate(booking.bookings[0].to_date, 'D MMM')}}</p>
                        </div>
                      </div>
                      <div class="flex items-center justify-between py-2">
                        <p class="text-sm font-medium text-gray-700">
                          Name
                        </p>
                        <div class="w-52 truncate text-right text-sm">
                          <p class="truncate text-gray-700">{{ `${booking.customer_info.first_name} ${booking.customer_info.last_name}` }}</p>
                        </div>
                      </div>
                      <div class="flex gap-4 items-center justify-between py-2">
                        <p class="text-sm font-medium text-gray-700">
                          Place
                        </p>
                        <div class="text-right truncate text-sm w-52">
                          <p class="truncate text-gray-700">
                            {{ booking.bookings[0].product.translation.title }}
                          </p>
                        </div>
                      </div>
                      <div class="flex gap-4 items-center justify-between py-2">
                        <p class="text-sm font-medium text-gray-700">
                          Booking placed
                        </p>
                        <div class="flex-auto text-right text-gray-700 truncate text-sm">
                          <p>{{ relativeFormatDate(booking.created_at) }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="absolute top-2 right-2">
                      <div class="btn btn-sm btn-white rounded-md px-2 py-0.5 rounded-md hover:bg-gray-100">
                        <span class="flex items-center gap-0.5 text-sm">
                          Go
                          <ArrowSmRightIcon class="h-5 w-5 text-highlight-500" />
                        </span>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
            <div v-if="bookings.items.length === 0" class="w-full py-4 border-t">
              <div class="py-8">
                <EmptyTable @reset-filters="resetFilters" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Popper from 'vue3-popper';
import { ArrowSmRightIcon, ChevronUpIcon } from '@heroicons/vue/solid'
import EmptyTable from "@/components/UI/EmptyStates/EmptyTable";

export default {
  name: "BookingsList",
  components: {
    EmptyTable,
    Popper,
    ArrowSmRightIcon,
    ChevronUpIcon
  },
  props: {
    bookings: {
      type: Object
    }
  },
  methods:{
    resetFilters() {
      this.$router.push({ query: null });
    },
    sort(field, type) {

      if(this.$route.query.field !== field) {
        this.$router.push({
          query: {
            ...this.$route.query,
            field,
            type
          }
        })
      } else {
        this.$router.push({
          query: {
            ...this.$route.query,
            field,
            type: this.$route.query.type === 'ASC' ? 'DESC' : type
          }
        })
      }
    },
    formatDate(dateString, formatString) {
      const date = dayjs(dateString);
      // Then specify how you want your dates to be formatted
      return date.format(formatString);
    },
    relativeFormatDate(dateString) {
      dayjs.extend(relativeTime);
      return dayjs(dateString).fromNow();
    },
    difference(from, to) {
      const diff = dayjs(to).diff(from, 'days')
      return diff > 1 ? `${diff} nights` : `${diff} night`
    }
  }
};
</script>

<style scoped>
:deep(.popper) {
  @apply bg-universeBlue rounded-md px-2 py-2 text-white
}

:deep(.popper #arrow::before) {
  @apply bg-universeBlue
}

</style>
