x<template>
  <div>
    <div class="border-b bg-white">
      <div class="max-w-4xl mx-auto py-3 px-3">
        <div class="md:flex md:items-center md:justify-between">
          <div class="flex-1 min-w-0">
            <h1 class="md:text-xl font-medium leading-7 text-gray-800 sm:text-2xl sm:truncate">
              Bookings
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gray-100 pt-4 lg:pt-0 min-h-screen">
      <div class="max-w-4xl mx-auto px-4 pb-10 lg:py-4 lg:pb-24 lg:px-8">
        <div v-if="$apollo.queries.bookingsList">
          <div v-if="(bookingsList?.count > 0) || (!bookingsList?.count && Object.keys($route.query).length > 0)">
            <div>
              <SearchAndFiltering />
            </div>
            <div v-if="!$apollo.queries.bookingsList.loading">
              <BookingsList :bookings="bookingsList"  />
              <div v-if="bookingsList.count > 1" class="mt-4">
                <Pagination
                  :perPage="perPage"
                  :total-results="bookingsList.count"
                  :page="+$route.query.page || 1"
                  label="bookings" />
              </div>
            </div>
            <div v-else class="py-8 mt-12 flex justify-center">
              <LoadingSpinner outer-class="h-8 w-8 text-water" />
            </div>
          </div>
          <div v-if="bookingsList?.count === 0 && Object.keys($route.query).length === 0" class="py-8">
            <div class="text-center">
              <h3 class="mt-2 font-medium text-lg text-gray-900">No bookings yet</h3>
              <p class="mt-1 text-sm text-gray-500">Your future bookings will appear here.</p>
            </div>
          </div>
        </div>
        <div v-else class="min-h-screen">
          <div class="hidden">
            <div class="h-10 w-full mb-2 rounded-t-lg bg-gray-200 animate-pulse"></div>
            <div v-for="index in 10" :key="index">
              <div class="h-16 py-2 w-full mb-2 rounded-lg bg-gray-200 animate-pulse">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchAndFiltering from "@/components/views/Overview/Bookings/BookingSearchAndFiltering";
import BookingsList from "@/components/views/Overview/Bookings/BookingList";
import Pagination from "@/components/UI/Navigation/Pagination";
import { GET_ORDERS } from "@/graphql/orders/queries";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";
// import CheckboxUI from "@/components/UI/Inputs/CheckboxUI";



export default {
  name: 'Bookings',
  components: { LoadingSpinner, BookingsList, SearchAndFiltering, Pagination },
  apollo: {
    bookingsList: {
      query: GET_ORDERS,
      update: data => data.getOrders,
      variables () {
        return {
          data: {
            status: this.$route.query?.status || ['USER_INFO','PAID'],
            all: (this.$route.query?.showall === 'true') || false,
            pagination: {
              page: Number(this.$route.query.page) || 1,
              perPage: this.perPage,
              orderBy: {
                field: this.$route.query.field || this.orderBy.field,
                type: this.$route.query.type || this.orderBy.type
              }
            },
            search: this.$route.query.search || ''
          }
        }
      }
    }
  },
  data() {
    return {
      // bookingFiltering: {
      //   component: CheckboxUI
      // }
      perPage: 10,
      allStatus: false,
      // showAll: false,
      orderBy: {
        field: 'CHECKIN',
        type: 'ASC'
      }
    }
  },
  methods: {
    searchHandler(searchText) {
      this.$router.push({ query: {
            ...this.$route.query,
            page: undefined,
            search: searchText || undefined
      } })
    }
  }

};
</script>

<style scoped>

</style>
